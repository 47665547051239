import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
//STYLED
import { H2, Text, LabelBW } from "../../utils/typo"
import { theme } from "../../utils/theme"
//COMPONENTS
import Button from "../button/Button"
import { CardWrapper, ContainerMob } from "../../utils/utils"

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  p {
    white-space: pre-wrap;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: start;
  }
  li {
    font-weight: 900;
  }
`

class OfertaInfo extends React.Component {
  render() {
    return (
      <ContainerMob>
        <InfoWrapper
          data-sal={`slide-${this.props.sal || "right"}`}
          data-sal-duration="600"
        >
          <LabelBW
            weight="300"
            textTransform="uppercase"
            colorFont={theme.color.grey5}
          >
            {this.props.heading.map(i => (
              <span key={i}>
                {i}
                <b> • </b>
              </span>
            ))}
          </LabelBW>
          <H2 mt="2rem" mb="1rem">
            {this.props.title}
          </H2>
          <Text weight="500" mb="2rem">
            — {this.props.intro}
          </Text>
          <CardWrapper colGap="4rem" l="2" xl="2" m="2">
            <GatsbyImage
              image={this.props.image}
              alt={this.props.alt}
              objectFit="contain"
            />
            <ul>
              {this.props.lista.map(li => (
                <li key={li}>{li}</li>
              ))}
            </ul>
          </CardWrapper>

          <Text mb="2rem" mt="2rem">
            {this.props.text}
          </Text>
          {this.props.link === "/" ? (
            <></>
          ) : (
            <Button link={this.props.link}>Więcej</Button>
          )}
        </InfoWrapper>
      </ContainerMob>
    )
  }
}
export default OfertaInfo
