import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { down } from "styled-breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
//DATA
//STYLES
import { H4, H2, LabelBW, Text } from "../utils/typo"
import {
  ContainerMob,
  Section,
  Logo,
  CardWrapper,
  Pattern,
} from "../utils/utils"
//COMPONENTS
import Seo from "../components/seo/SEO"
import Layout from "../layout/layout"
import OfertaIks from "../components/oferta/OfertaIks"
import { theme } from "../utils/theme"
import Button from "../components/button/Button"

const Card = styled(Link)`
  grid-column: ${props => props.span || "span 1"};
  background-color: ${props => props.bgcolor || props.theme.color.grey9};
  outline: 1px solid lightgrey;
  outline-offset: -0.5px;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h4 {
    line-height: 1.2;
  }

  button {
    margin-bottom: 0;
  }

  p:nth-of-type(2) {
    display: none;
  }

  ${down("sm")} {
    grid-column: span 1;

    p {
      display: none;
    }
  }

  ${down("xs")} {
    p:nth-of-type(2) {
      display: inline;
    }
  }

  &:hover {
    background-color: ${props => props.theme.color.base1};
  }
`

const Lines = styled(Pattern)`
  height: 50vh;
  position: relative;
  z-index: -10;
  margin-top: -25vh;
`

const Oferta = ({
  data: {
    mdx: {
      frontmatter: { oferta },
    },
  },
}) => {
  return (
    <div>
      <Seo title="Oferta" slug="/oferta" />
      <Layout>
        <Logo />
        <Section pt="6rem" mb="0">
          <ContainerMob>
            <LabelBW mb="2rem">Oferta</LabelBW>
            <H2 mb="1rem" as="h1" lineHeight="1.1">
              IKS DRUK — drukarnia Gliwice Politechnika Śląska
            </H2>
            <Text weight="700" mb="2rem">
              — Zapraszamy do zapoznania się z bogatą ofertą usług i produktów
              naszej firmy
            </Text>
          </ContainerMob>
          <CardWrapper xl="4" l="4" m="4">
            {oferta.map(item => (
              <Card
                key={item.id}
                to={"/oferta#" + item.id}
                span={item.span}
                bgcolor={
                  item.id === 1
                    ? theme.color.base4
                    : item.id === 2
                    ? theme.color.sec4
                    : item.id === 3
                    ? theme.color.grey8
                    : item.id === 4
                    ? theme.color.grey4
                    : theme.color.grey9
                }
              >
                <div>
                  <span>
                    <H4 mb="1rem">{item.title}</H4>
                    <Text>{item.span === "span 2" ? item.intro : ""}</Text>
                    <Text>{item.intro}</Text>
                  </span>
                  <Button>Więcej</Button>
                </div>

                <GatsbyImage
                  image={item.logo.file.childImageSharp.gatsbyImageData}
                  alt={item.logo.alt}
                />
              </Card>
            ))}
          </CardWrapper>

          <Lines />
        </Section>

        <>
          {oferta.map(item => (
            <Section
              id={item.id}
              key={item.id}
              pt="7rem"
              pb="5rem"
              bgcolor={item.id % 2 == 1 ? "white" : theme.color.grey9}
            >
              <OfertaIks
                heading={item.heading}
                title={item.title}
                intro={item.intro}
                lista={item.lista}
                text={item.text}
                image={item.mainImage.file.childImageSharp.gatsbyImageData}
                alt={item.mainImage.alt}
                link={item.link}
              />
            </Section>
          ))}
        </>
      </Layout>
    </div>
  )
}
export default Oferta

export const query = graphql`
  query {
    mdx(slug: { eq: "oferta" }) {
      frontmatter {
        oferta {
          heading
          id
          span
          intro
          link
          lista
          text
          title
          mainImage {
            alt
            file {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  transformOptions: { fit: CONTAIN }
                  placeholder: BLURRED
                )
              }
            }
          }
          logo {
            alt
            file {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  transformOptions: { fit: CONTAIN }
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`
